<template>
  <div id="Leftsidebar">
    <div class="sidebar-widget categories">
      <h4><router-link to="/myaccount">My Account</router-link></h4>
      <div class="category-box">
        <ul class="list-style">
          <li v-if="user.role !== 'table'">
            <!--<a href="shop-left-sidebar.html"
                          >Dishes<i class="flaticon-right-arrow-angle"></i>
                        </a>-->
            <router-link to="/manageaddress" routerLinkActive="active-link"
              >Manage Address<i class="flaticon-right-arrow-angle"></i
            ></router-link>
          </li>
          <li>
            <!--<a href="shop-left-sidebar.html"
                          >Nuts & Greens<i class="flaticon-right-arrow-angle"></i>
                        </a>-->
            <router-link to="/myorders"
              >My Orders<i class="flaticon-right-arrow-angle"></i
            ></router-link>
          </li>
          <li v-if="user.role !== 'table'">
            <!--<a href="shop-left-sidebar.html"
                          >Smoothies<i class="flaticon-right-arrow-angle"></i>
                        </a>-->
            <router-link to="/mywallet"
              >My Wallet<i class="flaticon-right-arrow-angle"></i
            ></router-link>
          </li>
          <!-- <li v-if="user.role !== 'table'"> -->
          <!--<a href="shop-left-sidebar.html"
                          >Vegetables<i class="flaticon-right-arrow-angle"></i>
                        </a>-->
          <!-- <router-link to="/shop">
              Help & FAQs<i class="flaticon-right-arrow-angle"></i
            ></router-link> -->
          <!-- </li> -->
          <li>
            <!--<a href="shop-left-sidebar.html"
                          >Takeaway Pack<i class="flaticon-right-arrow-angle"></i>
                        </a>-->
            <a class="cursor-pointer" @click="logOut"
              >Logout<i class="flaticon-right-arrow-angle"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { getLocalStorage } from "@/store/service";
export default {
  data() {
    return {
      user: getLocalStorage("userData"),
    };
  },
  methods: {
    logOut() {
      localStorage.removeItem("userData");
      localStorage.removeItem("cart");
      localStorage.removeItem("userDataVerify");
      localStorage.removeItem("submitOrder");
      this.$router.push("/shop");
    },
  },
  name: "Leftsidebar",
};
</script>
