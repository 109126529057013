<template>
  <div id="myorders">
    <Header></Header>
    <div class="breadcrumb-wrap bg-f br-1">
      <div class="overlay bg-black op-9"></div>
      <img :src="shape1" alt="Image" class="br-shape-1" />
      <img :src="shape2" alt="Image" class="br-shape-2" />
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="breadcrumb-title">
              <h2>My Orders</h2>
              <ul class="breadcrumb-menu list-style">
                <li>
                  <!--<a href="index-2.html">Home </a>--><router-link
                    to="/"
                    style="color: white"
                    >Home</router-link
                  >
                </li>
                <li>My Orders</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="blog-details-wrap ptb-100">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-4 col-lg-12 order-xl-1 order-lg-2 order-md-2 order-2"
          >
            <div class="sidebar">
              <Leftsidebar></Leftsidebar>
            </div>
          </div>
          <div
            class="col-xl-8 col-lg-12 order-xl-2 order-lg-1 order-md-1 order-1"
            id="mainitems"
          >
            <h4 class="border-bottom pb-4">
              <i
                class="fa fa-shopping-bag text-default mr-3"
                aria-hidden="true"
              ></i>
              My Orders
            </h4>
            <div class="row mb-5">
              <div class="container">
                <div class="row">
                  <div class="col-md-12 mb-20">
                    <div class="wishlist-table">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Order Id</th>
                            <th scope="col">Description</th>
                            <th scope="col">DeliverIn</th>
                            <th scope="col">Status</th>
                            <th scope="col">Total Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="line"
                            v-for="order in pageOfItems"
                            :key="order.id"
                          >
                            <td>
                              <div class="wh-item-info">
                                <router-link
                                  :to="`/orderdetails/${order.id}/${order.unique_order_id}`"
                                  class="font-weight-bold"
                                  >{{ order.unique_order_id }}</router-link
                                >
                                <p>{{ order.created_at }}</p>
                              </div>
                            </td>
                            <td>
                              <div class="wh-item-info">
                                <a href="#" class="font-weight-bold">{{
                                  order.restaurant.name
                                }}</a>
                                <p>
                                  <span
                                    v-for="orderItems in order.orderitems"
                                    :key="orderItems.id"
                                    >{{ orderItems.name }},
                                  </span>
                                </p>
                              </div>
                            </td>
                            <td>
                              <div class="wh-item-info">
                                <p>
                                  <span
                                    v-if="order.orderstatus_id === 5"
                                    class="font-weight-bold text-right text-success"
                                  >
                                    Order Completed</span
                                  >
                                  <span
                                    class="font-weight-bold"
                                    v-else-if="order.order_timing != null"
                                  >
                                    {{
                                      order.order_timing > 1
                                        ? order.order_timing + " minutes"
                                        : order.order_timing + " hour"
                                    }},
                                  </span>
                                  <span
                                    v-else
                                    class="font-weight-bold text-right"
                                  >
                                    Order is not accepted yet.</span
                                  >
                                </p>
                              </div>
                            </td>
                            <td>
                              <span
                                class="wh-tem-status text-warning font-weight-bold"
                                v-if="order.orderstatus_id === 1"
                                ><router-link
                                  :to="`/orderdetails/${order.id}/${order.unique_order_id}`"
                                >
                                  Order Placed &rsaquo;</router-link
                                ></span
                              >
                              <span
                                class="wh-tem-status text-warning font-weight-bold"
                                v-if="order.orderstatus_id === 2"
                                ><router-link
                                  :to="`/orderdetails/${order.id}/${order.unique_order_id}`"
                                >
                                  Order Accepted &rsaquo;</router-link
                                ></span
                              >
                              <span
                                class="wh-tem-status text-warning font-weight-bold"
                                v-if="order.orderstatus_id === 3"
                                ><router-link
                                  :to="`/orderdetails/${order.id}/${order.unique_order_id}`"
                                >
                                  Delivery Assigned &rsaquo;</router-link
                                ></span
                              >
                              <span
                                class="wh-tem-status text-warning font-weight-bold"
                                v-if="order.orderstatus_id === 4"
                                ><router-link
                                  :to="`/orderdetails/${order.id}/${order.unique_order_id}`"
                                >
                                  Picked Up &rsaquo;</router-link
                                ></span
                              >
                              <span
                                class="wh-tem-status text-success font-weight-bold"
                                v-if="order.orderstatus_id === 5"
                                ><router-link
                                  :to="`/orderdetails/${order.id}/${order.unique_order_id}`"
                                  >Completed &rsaquo;</router-link
                                ></span
                              >
                              <span
                                class="wh-tem-status text-danger font-weight-bold"
                                v-if="order.orderstatus_id === 6"
                                ><router-link
                                  :to="`/orderdetails/${order.id}/${order.unique_order_id}`"
                                  >Cancelled &rsaquo;</router-link
                                ></span
                              >
                              <span
                                class="wh-tem-status text-warning font-weight-bold"
                                v-if="order.orderstatus_id === 7"
                                ><router-link
                                  :to="`/orderdetails/${order.id}/${order.unique_order_id}`"
                                >
                                  Ready for Pickup &rsaquo;</router-link
                                ></span
                              >
                              <span
                                class="wh-tem-status text-warning font-weight-bold hide"
                                v-if="order.orderstatus_id === 8"
                                ><router-link
                                  :to="`/orderdetails/${order.id}/${order.unique_order_id}`"
                                  >Awaiting Payment &rsaquo;</router-link
                                ></span
                              >
                              <span
                                class="wh-tem-status text-danger font-weight-bold hide"
                                v-if="order.orderstatus_id === 9"
                                ><router-link
                                  :to="`/orderdetails/${order.id}/${order.unique_order_id}`"
                                  >Payment Failed &rsaquo;</router-link
                                ></span
                              >
                            </td>
                            <td>
                              <span class="wh-tem-price"
                                >${{ order.total }}</span
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="">
                        <jw-pagination
                          :items="orders"
                          @changePage="onChangePage"
                        ></jw-pagination>
                      </div>
                    </div>
                    <audio controls id="audioFile">
                      <source src="tone.ogg" type="audio/ogg" />
                      <source
                        src="../../assets/img/tone.mp3"
                        type="audio/mpeg"
                      />
                    </audio>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/views/layouts/Header.vue";
import Leftsidebar from "@/views/sidebar/Leftsidebar.vue";
import Footer from "@/views/layouts/Footer.vue";
import { getOrders } from "@/store/api";
import { getLocalStorage } from "@/store/service";
export default {
  name: "MyOrders",
  data() {
    return {
      shape1: require("@/assets/img/shape-1.png"),
      shape2: require("@/assets/img/shape-2.png"),
      form: {
        value: "",
      },
      interval: "",
      orders: [],
      pageOfItems: [],
      oldItems: [],
    };
  },
  components: {
    Header,
    Leftsidebar,
    Footer,
  },
  mounted() {
    var audio = document.getElementById("audioFile");
    audio.controls = false;
    this.getOrder();
    this.interval = setInterval(() => {
      this.getOrder();
    }, 10000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    categoryFilter(category) {
      this.category = category;
      document.getElementById("mainitems").scrollIntoView({
        behavior: "smooth",
      });
    },
    getOrder() {
      getOrders(this.form, getLocalStorage("userData").role).then((res) => {
        console.log(res.data);
        this.orders = res.data;
        if (this.oldItems.length > 0) {
          for (let i = 0; i < this.orders.length; i++) {
            if (
              this.orders[i].id === this.oldItems[i].id &&
              this.orders[i].orderstatus_id !== this.oldItems[i].orderstatus_id
            ) {
              document.getElementById("audioFile").play();
              this.$toast.success(
                this.orders[i].unique_order_id + " Order status changed.",
                {
                  timeout: 1000,
                }
              );
            }
          }
          this.oldItems = res.data;
        } else {
          this.oldItems = res.data;
        }
      });
    },
  },
};
</script>
